import axios from "axios";
export const func = {
  hoverWord(word) {
    let result = [];
    for (var i = 0; i < word.length; i++) {
      if (word[i] == " ") {
        result.push(
          `<span class="hoverText__item hoverText__empty">${word[i]}</span>`
        );
      } else {
        result.push(`<span class="hoverText__item">${word[i]}</span>`);
      }
    }
    return `<div class="hoverText">${result.join("")}</div>`;
  },
  async compareDate() {
    const response = await axios.get(`/api/user/show-event`);
    return response.data.show_event
  },
  // },
};
