import Vue from "vue";
import VueRouter from "vue-router";

import MainLayout from "../layouts/Main.vue";

import {func} from "./../globalFunc.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "",
      layout: MainLayout,
    },
    component: () => import("../views/MainPage.vue"),
  },
  {
    path: "/co-to",
    name: "Co to?",
    meta: {
      title: "Co to?",
      layout: MainLayout,
    },
    component: () => import("../views/WhatIsIt.vue"),
  },
  {
    path: "/plan",
    name: "Plan",
    meta: {
      title: "Plan",
      layout: MainLayout,
    },
    component: () => import("../views/Plan.vue"),
    // component: () => import("../views/MainPage.vue"),
  },
  {
    path: "/FAQ",
    name: "FAQ",
    meta: {
      title: "FAQ",
      layout: MainLayout,
    },
    component: () => import("../views/FAQ.vue"),
  },
  {
    path: "/informacje",
    name: "Informacje",
    meta: {
      title: "Informacje",
      layout: MainLayout,
    },
    component: () => import("../views/Information.vue"),
  },
  {
    path: "/galeria",
    name: "Galeria",
    meta: {
      title: "Galeria",
      layout: MainLayout,
    },
    component: () => import("../views/Gallery.vue"),
  },

  // {
  //   path: "/koszulki",
  //   name: "Koszulki",
  //   meta: {
  //     title: "Koszulki",
  //     layout: MainLayout,
  //   },
  //   component: () => import("../views/Tshirt.vue"),
  // },
  {
    path: "/do-pobrania",
    name: "Do pobrania",
    meta: {
      title: "Do pobrania",
      layout: MainLayout,
    },
    component: () => import("../views/ToDownload.vue"),
  },
  {
    path: "/rejestracja",
    name: "Rejestracja",
    meta: {
      title: "Rejestracja",
      layout: MainLayout,
    },
    component: () => import("../views/Registration.vue"),
    beforeEnter: async (to, from, next) => {
      if (await func.compareDate()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/warsztaty",
    name: "Warsztaty",
    meta: {
      title: "Warsztaty",
      layout: MainLayout,
    },
    component: () => import("../views/Workshops.vue"),
    beforeEnter: async (to, from, next) => {
      if (await func.compareDate()) {
        next();
      } else {
        next("/");
      }
    },
  },
  // {
  //   path: "/rejestracja",
  //   name: "rejestracja",
  //   meta: {
  //     title: "rejestracja",
  //     layout: MainLayout,
  //   },
  //   component: () => import("../views/MainPage.vue"),
  //   beforeEnter: (to, from, next) => {
  //     next("/");
  //   },
  // },

  {
    path: "/koszulki",
    name: "koszulki",
    meta: {
      title: "koszulki",
      layout: MainLayout,
    },
    component: () => import("../views/MainPage.vue"),
    beforeEnter: (to, from, next) => {
      next("/");
    },
  },
  // {
  //   path: "/male-paradiso",
  //   name: "Małe Paradiso",
  //   meta: {
  //     title: "Małe Paradiso",
  //     layout: MainLayout,
  //   },
  //   component: () => import("../views/MainPage.vue"),
  //       beforeEnter: (to, from, next) => {
  //       next('/');
  //   },
  // },
  {
    path: "/kontakt",
    name: "Kontakt",
    meta: {
      title: "Kontakt",
      layout: MainLayout,
    },
    component: () => import("../views/Contact.vue"),
  },
  // {
  //   path: "/zaloguj",
  //   name: "Zaloguj",
  //   meta: {
  //     title: "Zaloguj się",
  //     layout: MainLayout,
  //   },
  //   component: () => import("../views/Login.vue"),
  // },
  // {
  //   path: "/konto",
  //   name: "Konto",
  //   meta: {
  //     title: "Twoje konto",
  //     layout: MainLayout,
  //   },
  //   component: () => import("../views/Account.vue"),
  // },
  {
    path: "/patroni",
    name: "Patroni",
    meta: {
      title: "Patroni",
      layout: MainLayout,
    },
    component: () => import("../views/Patrons.vue"),
  },
  {
    path: "/patroni/chiara-luce-badano",
    name: "bł. Chiara Luce Badano",
    meta: {
      title: "bł. Chiara Luce Badano",
      layout: MainLayout,
    },
    component: () => import("../views/patrons/Chiara.vue"),
  },
  {
    path: "/patroni/filip-neri",
    name: "Św. Filip Neri",
    meta: {
      title: "Św. Filip Neri",
      layout: MainLayout,
    },
    component: () => import("../views/patrons/FilipNeri.vue"),
  },
  {
    path: "/patroni/matka-boza",
    name: "Matka Boża",
    meta: {
      title: "Matka Boża",
      layout: MainLayout,
    },
    component: () => import("../views/patrons/MatkaBoza.vue"),
  },
  {
    path: "/patroni/jan-henryk-newman",
    name: "Jan Henryk Newman",
    meta: {
      title: "Jan Henryk Newman",
      layout: MainLayout,
    },
    component: () => import("../views/patrons/Newman.vue"),
  },
  {
    path: "/patroni/jose-sanchez-del-rio",
    name: "Św. Jose Sanchez del Rio",
    meta: {
      title: "Św. Jose Sanchez del Rio",
      layout: MainLayout,
    },
    component: () => import("../views/patrons/JoseSanchezDelRio.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
